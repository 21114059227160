import { graphql } from "gatsby";
import React from "react";
import BottomCTA from "../components/BottomCta";
import AnalyzeSection from "../components/IndexPage/AnalyzeSection";
import AudienceSection from "../components/IndexPage/AudienceSection";
import GoalSection from "../components/IndexPage/GoalSection";
import HeroSection from "../components/IndexPage/HeroSection";
import PlatformSection from "../components/IndexPage/PlatformSection";
import BMSection from "../components/IndexPage/BMSection";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import CustomersVote from "../components/IndexPage/CustomersVoteSection";
import { PAGE } from "../constants/pages";

interface SellicsJoinsPerpetuaProps {
  data: any;
}

const SellicsJoinsPerpetua: React.FC<SellicsJoinsPerpetuaProps> = ({
  data
}) => {
  const { helpers } = useSegment();
  const { language } = useI18next();
  const { t } = useTranslation();

  const pageData = data?.homepage?.edges[0]?.node;

  const bmFeatures = [
    { text: t("Monthly trends"), className: "top-left" },
    {
      text: t("Easy & simple: analyze your performance in seconds"),
      className: "bottom-left"
    },
    {
      text: t("Detailed benchmarks for 20,000 categories"),
      className: "right-middle"
    }
  ];

  const bmCta = {
    text: t("Learn more"),
    route: t("/amazon-advertising-benchmark-tool/")
  };

  const customersVote = {
    subtitle: t("It’s no coincidence"),
    title: t("Customers have voted us as a Leader on G2 four times in a row.")
  };

  const heroCTAs = {
    first: {
      route: t("https://app.perpetua.io/signup"),
      text: t("Get Started")
    },
    second: { route: t("/demo/"), text: t("Book a Demo") },
    third: {
      route: t("https://perpetua.io/blog-perpetua-sellics-join-forces/"),
      text: t("Read the memo")
    }
  };

  return (
    <Layout
      primaryButtonId={CTA_ID.getStartedNav.id}
      secondaryButtonId={CTA_ID.signinNav.id}
      locale={language}
    >
      <SEO
        title={t("Sellics joins forces with Perpetua")}
        description={t(
          "Together we are committed to bringing you the best single platform to profitably scale growth on Amazon, Walmart, and other top eCommerce marketplaces."
        )}
        image={`https://perpetua.io${data?.openGraphImage?.publicURL}`}
      />
      <HeroSection
        title={[t("Sellics joins forces with"), t("Perpetua")]}
        gradientPosition={2}
        description={t(
          "Together we are committed to bringing you the best single platform to profitably scale growth on Amazon, Walmart, and other top eCommerce marketplaces."
        )}
        cta={heroCTAs?.first}
        secondaryCta={heroCTAs?.second}
        thirdCta={heroCTAs?.third}
        page={PAGE.SELLICS_JOINS_PERPETUA}
      />
      <PlatformSection
        title={[t("Accelerate"), t("your eCommerce advertising")]}
        gradientPosition={1}
        description={t(
          "Sellics adds 8+ years of Amazon marketplace & retail optimization expertise to Perpetua's strength in advertising automation and intelligence. Together we aim to empower the world's most sophisticated brands and agencies like yours with the right tools to win."
        )}
        features={pageData?.platformFeatures}
      />
      <GoalSection
        title={[t("Launch"), t("goals,"), t("not campaigns")]}
        gradientPosition={2}
        description={t(
          "Leverage automated campaign creation, execution, and optimization to save time and money. Our ad engine executes tactically so you can focus on driving growth on your strategic objectives."
        )}
        backgroundImage={pageData?.goalSectionBackgroundImage?.gatsbyImageData}
        features={pageData?.goalFeatures}
        page={PAGE.SELLICS_JOINS_PERPETUA}
      />
      <BMSection
        title={[
          t("Your"),
          t("PPC Growth Opportunities"),
          t("are only 1 click away")
        ]}
        gradientPosition={2}
        description={t(
          "See how you stack up next to others in your categories and identify areas of improvement."
        )}
        features={bmFeatures}
        cta={bmCta}
        page={PAGE.SELLICS_JOINS_PERPETUA}
      />
      <AudienceSection
        title={[t("Always-on"), t("bid optimization")]}
        gradientPosition={1}
        description={t(
          "Prevent your campaigns from going dark while driving profitable market share growth by maximizing the efficiency of your spend with customizable, smart recommendations and contextual, conversion-based bidding algorithms."
        )}
        page={PAGE.SELLICS_JOINS_PERPETUA}
      />
      <AnalyzeSection
        title={[t("Measure the"), t("impact"), t("of your advertising")]}
        gradientPosition={2}
        description={t(
          "Our customizable, enterprise-level reporting allows you to understand, at a single glance, how your business and products are performing in your category across multiple channels and marketplaces."
        )}
        features={pageData?.analyzeFeatures}
        page={PAGE.SELLICS_JOINS_PERPETUA}
      />
      <CustomersVote
        title={customersVote?.subtitle}
        description={customersVote?.title}
        badges={pageData?.customersVoteBadges}
      />
      <BottomCTA
        title={t("Getting started is easy")}
        subtitle={t(
          "Everything you need to grow your ecommerce business, in one platform."
        )}
        primaryButtonText={t("Get started")}
        secondaryButtonText={t("Request a demo")}
        primaryButtonOnClick={() => {
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: t("Get started"),
              page_url: window.location.href,
              destination: t("https://app.perpetua.io/signup"),
              location: Location.BottomCta
            })
            .then(() => nav(t("https://app.perpetua.io/signup")));
        }}
        secondaryButtonOnClick={() => {
          trackView(Name.Cta, {
            category: Category.ButtonClick,
            type: Type.Button,
            text: t("Request a demo"),
            page_url: window.location.href,
            destination: t("/demo/"),
            location: Location.BottomCta
          });
          nav(t("/demo/"));
        }}
      />
    </Layout>
  );
};

export default SellicsJoinsPerpetua;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    homepage: allContentfulHomePageV2(
      filter: { node_locale: { eq: $language } }
    ) {
      edges {
        node {
          platformFeatures {
            id
            text
            svg {
              url
            }
          }
          goalSectionBackgroundImage {
            gatsbyImageData
            url
          }
          goalFeatures {
            id
            options {
              name
              inputs {
                input
                title
              }
            }
            name
          }
          analyzeFeatures {
            id
            options {
              name
              data {
                title
                amount
                changeAmount
                changeDirection
                position
              }
            }
            name
          }
          customersVoteBadges {
            svg {
              url
            }
            text
          }
        }
      }
    }
    openGraphImage: file(relativePath: { eq: "sellics-keyword/ogImage.png" }) {
      publicURL
    }
  }
`;
